import React, { Component } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import "@progress/kendo-theme-default/dist/all.css";
import "./scss/style.scss";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Cookies from "js-cookie";

const myTheme = createMuiTheme({
  // Theme settings
  palette: {
    type: "dark",
  },
});
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ForgotPassword = React.lazy(() =>
  import("./views/pages/ForgotPassword/ForgotPassword")
);

class App extends Component {
  state = {
    authUser: false,
    checkUser: false,
  };

  async componentDidMount() {
    var checkUser1 = Cookies.get("UserAuth");
    if (checkUser1) {
      this.setState({ authUser: true });
    }
  }

  render() {
    const authProps = {
      // isAuthenticated: this.state.isAuthenticated,
      // user: this.state.user,
      // setAuthStatus: this.setAuthStatus,
      // setUser: this.setUser
    };
    return (
      !this.state.checkUser && (
        <ThemeProvider theme={myTheme}>
          <HashRouter hashType={"noslash"}>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={(props) => <Register {...props} auth={authProps} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  exact
                  path="/forgotPassword"
                  name="ForgotPassword"
                  render={(props) => <ForgotPassword {...props} />}
                />
                <ProtectedRoute
                  user={this.state.authUser}
                  path="/"
                  auth={authProps}
                  component={TheLayout}
                />
                {/*<Route path="/" name="Home" render={props => <TheLayout {...props} auth={authProps} />} />*/}
              </Switch>
            </React.Suspense>
          </HashRouter>
        </ThemeProvider>
      )
    );
  }
}

export default App;

const ProtectedRoute = ({
  component: Component,
  user: user,
  auth: auth,
  ...rest
}) => {
  // let _AuthTokenHandler = new AuthTokenHandler();

  return (
    <ThemeProvider theme={myTheme}>
      <Route
        {...rest}
        render={(props) =>
          Cookies.get("UserAuth") ? (
            <Component {...props} prop={auth} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          )
        }
      />
    </ThemeProvider>
  );
};
