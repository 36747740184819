import { createStore } from 'redux';

const initialState = {
  sidebarShow: 'responsive',
  userData: {}
};

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action };
    case 'USER_DATA':
      return {
        ...state,
        userData: action.payload
      };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
